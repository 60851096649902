import { gql } from 'graphql-request';

const metafieldsIdentifier = `[
  {namespace: "availability", key: "click_and_collect_stores"},
  {namespace: "availability", key: "doordash_menu_ids"},
  {namespace: "availability", key: "store_location_ids"},
  {namespace: "global", key: "description_tag"},
  {namespace: "product", key: "summary"},
  {namespace: "custom", key: "product_lifecycle_status"}
]`;

export const SEARCH_PRODUCT_FIELDS = gql`
  fragment SearchProductFields on Product {
    id
    title
    handle
    tags
    images(first: 25) {
      edges {
        node {
          altText
          height
          transformedSrc
        }
      }
    }
    variants(first: 15) {
      edges {
        node {
          id
          sku
          title
          availableForSale
          quantityAvailable
          image {
            transformedSrc
          }
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
    metafields(identifiers: ${metafieldsIdentifier}) {
        namespace
        key
        value
    }
  }
`;
