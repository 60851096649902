import { gql } from 'graphql-request';
import { SEARCH_PRODUCT_FIELDS } from '@lib/shopify/queries/fragments/search-product-fields';

// Metafields identifiers for the search query, similar to the collection query
const metafieldsIdentifier = `[
  {namespace: "etl", key: "sap_id"},
  {namespace: "etl", key: "sap_level"},
  {namespace: "etl", key: "sap_parent"},
  {namespace: "global", key: "description_tag"},
  {namespace: "global", key: "title_tag"},
  {namespace: "filtering", key: "toggle_gift_filters"},
  {namespace: "custom", key: "enable_promo_cards"},
  {namespace: "custom", key: "enable_prismic_content"}
]`;

// Modified search query to include filters and metafields
const productsByQuery = gql`
  query search(
    $query: String!
    $filters: [ProductFilter!]
    $first: Int
    $after: String
    $sortKey: SearchSortKeys
    $reverse: Boolean
  ) {
    search(
      query: $query
      productFilters: $filters
      first: $first
      after: $after
      sortKey: $sortKey
      reverse: $reverse
      types: [PRODUCT]
    ) {
      edges {
        cursor
        node {
          ... on Product {
            ...SearchProductFields
            metafields(identifiers: ${metafieldsIdentifier}) {
              namespace
              key
              value
            }
          }
        }
      }
      pageInfo {
        hasNextPage
      }
      productFilters {
        id
        label
        type
        values {
          count
          id
          input
          label
        }
      }
    }
  }
  ${SEARCH_PRODUCT_FIELDS}
`;

export default productsByQuery;
